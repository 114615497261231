import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const WrapFeeBrochurePage = () => {
  const pdfUrl = `/Wrap-Fee-Brochure.pdf?v=${new Date().getTime()}`
  return (
    <Layout>
      <SEO title="Wrap Fee Brochure (ADV Part 2A)" />
      <Container>
        <h2 className="text-center font-size-base">
          Ant Money Advisors, LLC (“AMA”) <br />
          Wrap Fee Program Brochure
        </h2>
        <h3 className="text-underline font-size-base font-weight-normal mb-0">
          General Information
        </h3>
        <p>
          The Ant Money Advisors wrap fee program <a href={pdfUrl}>brochure</a>{" "}
          provides information about the qualifications and business practices
          of Ant Money Advisors. You can access and review the brochure by
          clicking <a href={pdfUrl}>here</a>. If you have any questions about
          the contents of this <a href={pdfUrl}>brochure</a>, please contact us
          at{" "}
          <a href="mailto:info@antmoneyadvisors.com">
            info@antmoneyadvisors.com
          </a>
          . The <a href={pdfUrl}>brochure</a> has not been approved or verified
          by the United States Securities and Exchange Commission, or by any
          state securities authority.
        </p>
        <hr />
        <p>
          <a href={pdfUrl} target="_blank" rel="noreferrer">
            Click here
          </a>{" "}
          to download the Wrap Fee Program Brochure.
        </p>
      </Container>
    </Layout>
  )
}

export default WrapFeeBrochurePage
